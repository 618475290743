import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Accordion`}</h1>
    <p>{`Accordion holds sections that hide or reveal related information.`}</p>
    <ComponentPreview componentName="accordion--default-story" hasReact hasAngular hasHTML titleAttr="Accordion Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Use Accordion to separate supplementary (lower-priority) content into digestible pieces.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Accordion sections are collapsed by default. Because this hides content, only use this to augment information already on the page. Avoid high-priority content.`}</li>
      <li parentName="ul">{`Avoid adding complex interactions like forms, buttons, and dropdowns. They complicate the user's experience.`}</li>
      <li parentName="ul">{`Do not put an Accordion inside of another Accordion.`}</li>
      <li parentName="ul">{`Keep Accordion titles succinct, clear, and short enough to be in one line.`}</li>
      <li parentName="ul">{`Accordion titles should be relevant to the content it reveals. A user should know exactly what to expect when they expand a section.`}</li>
      <li parentName="ul">{`If your Accordion only has one item, use the `}<a parentName="li" {...{
          "href": "https://sparkdesignsystem.com/using-spark/components/toggle"
        }}>{`Toggle Component`}</a>{` instead.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`An Accordion Item must have a clickable header that reveals or hides information.`}</li>
      <li parentName="ul">{`An Accordion Item must have a left aligned title.`}</li>
      <li parentName="ul">{`An Accordion Item must contain content.`}</li>
      <li parentName="ul">{`An Accordion Item must have a right aligned `}{`\``}{`chevron-up-circle`}{`\``}{` or `}{`\``}{`chevron-down-circle`}{`\``}{` Icon`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`Accessible through keyboard.`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{` and `}<inlineCode parentName="li">{`Shift`}</inlineCode>{` + `}<inlineCode parentName="li">{`Tab`}</inlineCode>{` keys navigate through Accordion item headings.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` and `}<inlineCode parentName="li">{`Space`}</inlineCode>{` keys expands or collapses each section.`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      